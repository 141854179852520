.slideOne{
    height: 420px;
    background-color: #062341;
    position: relative;
}
.slideTwo{
    min-height: 320px;
    padding: 20px 0;
}
.slideOneTitle{
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    color: #ffffff;
}
.slideOneTitle p{
    font-family: Inter, sans-serif !important;
    margin-bottom: 0;
    font-size: 41px;
    font-weight: 500;
    padding: 0 40px;
}
.footer{
    position: absolute;
    bottom: 0;
    z-index: 99999;
    margin-left: 40px;
    color: #ffffff !important;
    padding-bottom: 15px;
}
.copy{
    float: left;
    line-height: 41px;
    margin-left: 10px;
    font-size: 12px;
}
.metricBox{
    border: 1px solid #CBD5E0;
    padding: 10px;
    border-radius: 5px;
}
.viewHeading{
    font-size: 15px;
    background-color: #062341;
    color: #ffffff;
    text-align: center;
    padding: 6px 0;
    margin-top: 10px;
}
.metricBox dl{
    margin-bottom: 10px !important;
}
.slideTwoTitle{
    color: #062341;
    text-align: center;
    padding: 5px 0;
    font-size: 18px;
    font-weight: 600;
    /* border-bottom: 2px solid #062341; */
    width: auto;
    margin: 0 auto;
    margin-bottom: 10px;
    max-width: 150px;
}
