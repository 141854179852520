.overrideClass{
    
}
.orgMenuContainer{
    margin: 0 16px 0 17px;
    text-transform: capitalize;
}
.orgMenuContainer p{
    flex: 1;
    text-align: left;
    font-size: 0.875rem;
    line-height: 1;
    vertical-align: middle;
    color: #3e4b5b;
}
.orgMenuContainer span{
    flex: 1 1;
    text-align: left;
    font-size: 0.875rem;
    line-height: 1;
    vertical-align: middle;
    color: #3e4b5b;
    float: left;
    max-width: 74%;
    width: 74%;
}
.themeButton{

}
.themeButton:before{
    content: "\f054";
    font-family: "Material Design Icons";
    display: block;
    position: relative;
    left: -10px;
    top: 50%;
    transform: translateY(-10%);
    color: #a2a2a2;
    font-size: 0.75rem;
}
