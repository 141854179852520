.mainButton{
    background-color: transparent !important;
    color: #3e4b5b !important;
    font-weight: normal !important;
    width: 100% !important;
    text-align: left !important;
    justify-content: left !important;
}
.mainButton:hover{
    background: var(--chakra-colors-blackAlpha-50) !important;
}
.shoWcontent{

}
.drawerContent {
    position: fixed !important;
    left: 260px !important;
    top: 0px !important;
    bottom: 0px !important;
    width: 650px !important;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1) !important;
    transform: translateX(0%) translateY(0px) translateZ(0px) !important;
    overflow-y: auto !important;
    height: 100vh !important;
    max-width: 100% !important;
}
.h3{
    font-size: 18px !important;
    font-family: calibri !important;
}
.h4{
    font-size: 16px !important;
    font-family: calibri !important;
    color: #00a0da !important;
}
.h5{
    font-size: 14px !important;
    font-family: calibri !important;
    color: #00a0da !important;
}
.categoryContainer{
    margin-left: 20px;
}
.themeContainer{

}
.pillarContainer{
    margin-left: 20px;
}