.reportContainer{
    border: 2px solid #cbd5e0;
    height: 300px;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    flex-direction: column;
}
.header{

}
.headerLeft{
    float: left;
    color: #000000;
    font-weight: 900;
    overflow-wrap: anywhere;
    max-width: 90%;
}
.headerRight{
    float: right;
    position: relative;
    top: -50px;
}
.footer{
    margin-top: auto;
    flex-shrink: 0;
    border-top: 2px solid #cbd5e0;
}
.ButtonDownload{
    font-weight: 500 !important;
    font-size: 14px !important;
}
.contentContainer{
    text-align: center;
    padding: 10px 0;
}