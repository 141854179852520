.heading{
    margin-bottom: 0;
    line-height: 1.3 !important;
    font-size: 20px !important;
    scroll-margin-block: 6.875rem;
    font-family: calibri !important;
    letter-spacing: normal;
}
.headingParent{
    float: left;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}
.headingParentRight{
    float: right;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}
.emptyCOntainer{
    margin: 0 auto;
}
.entryContainer{
    width: 100%;
}
.entryContainer h3{
    font-family: Inter, sans-serif !important;
    font-size: 16px !important;
    letter-spacing: normal;
    margin-top: 15px;
    margin-bottom: 5px;
}
.entryInnerContainer{
    width: 100%;
    display: block;
    border: 1px solid #dadada;
    min-height: 100px;
    border-radius: 5px;
}
.Tag{
    border: 1px solid #dadada;
    padding: 10px 10px;
    text-align: left;
    border-radius: 5px;
    position: relative;
}
.tagInnerText{
    
}
.slidePane{
    max-width: 680px !important;   
}
.edit{
    background-color: transparent !important;
    padding: 0 !important;
    position: relative !important;
    top: -2px !important;
    left: 0px !important;
}
.leftMinus10{
    left:-15px !important;
    background-color: transparent !important;
}